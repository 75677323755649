<template>
	<div style="height:100%; width:100% ;">

		<div id="pie1" class="pie">

		</div>
		<div id="pie2" class="pie">

		</div>
		<div id="pie3" class="pie">

		</div>
		<div id="pie4" class="pie">

		</div>
		<div id="pie5" class="pie">

		</div>
		<div id="pie6" class="pie">

		</div>
    <div id="pie7" class="pie">

    </div>

	</div>
</template>

<script>

export default {

	data() {
		return {
			DataList: []

		};
	},
	mounted() {

		this.getData()
	},
	methods: {
		getData() {
			this.$http.post("/api/home_statistics").then(res => {
				if (res.data.dkl) {
					this.initPie1(res.data.dkl, 1)
				}
				if (res.data.sxl) {
					this.initPie1(res.data.sxl, 2)
				}
				if (res.data.ybspl) {
					this.initPie1(res.data.ybspl, 3)
				}
				if (res.data.ybtj) {
					this.initPie1(res.data.ybtj, 4)
				}
				if (res.data.zbspl) {
					this.initPie1(res.data.zbspl, 5)
				}
				if (res.data.zbtj) {
					this.initPie1(res.data.zbtj, 6)
				}
        if (res.data.tztj) {
          this.initPie1(res.data.tztj, 7)
        }
			})
		},
		initPie1(data, id) {
      let data2 = []
      let color = []
      if (data.ok_count === 0 && data.qb_count === 0) {
        data2 = [
          {value:0,name:'0人'}
        ]
        color.push("#cccccc")
      } else {
        data2 = [
          { value: data.ok_count, name: " " + data.ok_count + "人" },
          { value: data.qb_count - data.ok_count, name: (data.qb_count - data.ok_count) + "人" },

        ]
        color.push("#409EFF", "#cccccc")
      }
			console.log(data2)
			let option = {

				color: color,

				series: [
					{
						name: '比率',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '12',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: data2
					}
				]
			};
			if (id == 5) {
				console.log(option)
			}

			let myChart = this.$echarts.init(document.getElementById('pie' + id));
			myChart.setOption(option)
			$("#pie" + id).append("<div class='pietitle'>" + data.title + "</div><div class='piepercent'>" + parseInt((parseFloat(data.rate == 'NaN' ? 0 : data.rate) * 1000)) / 10 + "%</div>")
		}

	}
}


</script>

<style scoped="scoped">
.pie {
	width: 14%;
	height: 100%;
	display: inline-block;
	/* transform: scale(0.1,0.1); */
	transform-origin: left top;
	position: relative;
}
</style>
<style>
.pietitle {
	width: 100%;
	position: absolute;
	bottom: 0px;
	text-align: center;
	font-size: 12px;
}

.piepercent {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
	font-size: 12px;
}

.pie:hover .piepercent {
	display: none;
}
</style>
